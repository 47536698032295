import React from "react";

const AboutSection = () => {
  return (
    <section className="container section section__height" id="about">
      <h2 className="section__title">About</h2>
      <div className="about">
        <input type="radio" name="tabs" id="tab1" />
        <input type="radio" name="tabs" id="tab2" />
        <input type="radio" name="tabs" id="tab3" checked="checked" />
        <div className="browser">
          <div className="tabs">
            <label className="tab" htmlFor="tab1">
              <div className="text">
                <i className="fa fa-github-alt"></i>&nbsp;LinkedIn
              </div>
              <div className="close">+</div>
            </label>
            <label className="tab" htmlFor="tab2">
              <div className="text">
                <i className="fa fa-codepen"></i>&nbsp;University
              </div>
              <div className="close">+</div>
            </label>
            <label className="tab" htmlFor="tab3">
              <div className="text">
                <i className="fa fa-user-circle-o"></i>&nbsp;&nbsp;Alwaleed
              </div>
              <div className="close">+</div>
            </label>
          </div>
          <div className="toolbar">
            <div className="controls">
              <i className="fa fa-arrow-left"></i>
              <i className="fa fa-arrow-right"></i>
              <i className="fa fa-undo"></i>
            </div>
            <div className="url">
              <div className="text">https://instagram.com</div>
              <div className="text">https://hct.edu.om</div>
              <div className="text">https://alwaleedcv.com</div>
            </div>
          </div>
          <div className="display">
            <iframe
              frameBorder="0" // camelCase in JSX
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "400",
                height: "380",
                border: "0",
              }}
              src="https://www.linkedin.com/embed/feed/update/urn:li:activity:7113474756552617985"
            ></iframe>
            <iframe src="https://hct.edu.om/"></iframe>
            <iframe src="https://imvake.github.io/imvake/"></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
