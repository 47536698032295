import React, { useState } from "react";
import emailjs from "emailjs-com";
import Discord from "./Discord";
import useForm from "./useForm";

export const initialFormState = {
  data: {
    name: "",
    email: "",
    message: "",
  },
  error: {},
};

const ContactSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "message") {
      setMessage(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    PostToDiscord();

    // EmailJS configuration
    const serviceID = "service_79gnp9j";
    const templateID = "template_weybx6s";
    const userID = "C1cV2yLsGa_mykWyh";

    const templateParams = formData.data;

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log("Email sent:", response);
        setIsMessageSent(true);
        setIsError(false);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setIsMessageSent(false);
        setIsError(true);
      });
  };

  const { formData, setDynamicFormData, clearForm, isValid } =
    useForm(initialFormState);

  const { Send } = Discord(clearForm);

  const PostToDiscord = () => {
    const description = Object.entries(formData.data)
      .map((d) => `**${d[0]}** : ${d[1]}`)
      .join("\n");

    Send(description);
  };
  return (
    <section className="container section section__height">
      <div className="contacttt">
        <section className="get-in-touch" id="contactme">
          <h1 className="title">Get in touch</h1>
          <form onSubmit={handleSubmit} noValidate>
            <div className="form">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Name"
                  value={formData.data.name}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDynamicFormData(name, value);
                  }}
                />
                <label
                  className="input-field-icon icon-user"
                  htmlFor="name"
                ></label>
              </div>

              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={formData.data.email}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDynamicFormData(name, value);
                  }}
                />
                <label
                  className="input-field-icon icon-email"
                  htmlFor="email"
                ></label>
              </div>

              <div className="form-group">
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder="Message"
                  value={formData.data.message}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDynamicFormData(name, value);
                  }}
                  rows="1"
                ></textarea>
              </div>

              <button
                id="contact-send"
                className="btn btn-primary btn-lg btn-block"
                type="submit"
              >
                Send
              </button>
              {isMessageSent && (
                <div
                  id="sent"
                  style={{
                    display: "block",
                    backgroundColor: "rgb(79, 195, 79)",
                    textAlign: "center",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  Message Sent Successfully
                </div>
              )}
              {isError && (
                <div
                  id="err"
                  style={{
                    display: "block",
                    backgroundColor: "rgb(195, 79, 79)",
                    textAlign: "center",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  There Was an Error, Please Try Again Later
                </div>
              )}
            </div>
          </form>
        </section>
      </div>
    </section>
  );
};

export default ContactSection;
