import React from "react";
import { useEffect } from "react";
import { openTab, addHorizontalScroll } from "./Opentab.js"; // Import the functions

const SkillsSection = () => {
  useEffect(() => {
    // Call the function to add horizontal scroll on component mount
    addHorizontalScroll();
  }, []);

  // Rest of your code...
  // Use handleTabClick function to handle button clicks
  const handleTabClick = (evt, tab) => {
    openTab(evt, tab);
  };
  return (
    <section className="container section section__height skills" id="skills">
      <div className="dem">
        <div id="container">
          <span id="text1"></span>
          <span id="text2"></span>
        </div>
        <svg id="filters">
          <defs>
            <filter id="threshold">
              <feColorMatrix
                in="SourceGraphic"
                type="matrix"
                values="1 0 0 0 0
									0 1 0 0 0
									0 0 1 0 0
									0 0 0 255 -140"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <h2 className="section__title">Skills</h2>
      <input
        className="variation"
        id="solid"
        type="radio"
        value="1"
        name="border"
        checked="checked"
      />
      <label htmlFor="solid">
        <span className="circle"></span>
        <span>Flat UI</span>
      </label>
      <input
        className="variation"
        id="shadow"
        type="radio"
        value="2"
        name="border"
      />
      <label htmlFor="shadow">
        <span className="circle"></span>
        <span>Realistic</span>
      </label>
      <div className="folder">
        <div className="tabs">
          <button
            className="tab active"
            onClick={(e) => handleTabClick(e, "tab-1")}
          >
            <div>
              <span>Life</span>
            </div>
          </button>
          <button className="tab" onClick={(e) => handleTabClick(e, "tab-2")}>
            <div>
              <span>Python</span>
            </div>
          </button>
          <button className="tab" onClick={(e) => handleTabClick(e, "tab-3")}>
            <div>
              <span>Web Development</span>
            </div>
          </button>
          <button className="tab" onClick={(e) => handleTabClick(e, "tab-4")}>
            <div>
              <span>Mobile App</span>
            </div>
          </button>
          {/* <!-- <button className="tab" onclick="openTab(event, 'tab-3')">
                    <div><span>Baking</span></div>
                  </button>
                  <button className="tab" onclick="openTab(event, 'tab-4')">
                    <div><span>Cat</span></div>
                  </button> --> */}
        </div>
        <div className="content">
          <div className="content__inner" id="tab-1">
            <div className="page">
              <h3>My Life</h3>
              <p>
                <span>Alwaleed </span>I m still Student in &nbsp;
                <mark>
                  <a href="https://www.utas.edu.om/" style={{ color: "black" }}>
                    UTAS
                  </a>
                </mark>
                &nbsp; Studying Software Engineering, Love Studying and Trying
                to improve my skills by Self Teaching my slef
              </p>
              <p>
                <b>Previously Work..!</b>
              </p>
              <p>
                - Library: Worked there only to spend my free time instead of
                playing Video Games.
              </p>
              <p>- Clinic: Worked on a clinic as a computer technician.</p>
              <p>
                - OmanCloud: Worked on an Electronic Shop as a computer
                technician.
              </p>
              <p>
                improving my self by learning new stuff using online resources.
              </p>
            </div>
          </div>
          <div className="content__inner" id="tab-2">
            <div className="page">
              <h3>Python</h3>
              <p>
                i have Designed Few apps that mostly are serving me, few of them
                are serving Other
              </p>
              <p>
                <b>My Python Libraries Intrests are</b>
              </p>
              <p>
                - Web Scarping: One of the best Libraries iv designed which
                helps me Signing My courses Before the semester starts.
              </p>
              <p>
                - Tkinter GUI: I have an Automated whatsapp Reporter for a
                clinic that sends the report by only filling the inputs.
              </p>
              <p>
                - DataScience: having the ability to scrape a data's using
                various libraries & resources such as : Pandas, Numpy, R and
                Danfo.js
              </p>
            </div>
          </div>
          <div className="content__inner" id="tab-3">
            <div className="page">
              <h3>Web Development</h3>
              <p>
                Web Development was my guid going deep into my specialization, i
                have designed multiple of website that i m porud of, and these
                are my interests :
              </p>
              <p>
                -<mark> Bootstrap &nbsp;</mark>Having Advnaced Knowledge about
                Bootstrap classes and Components, making the website fully
                responsive.
              </p>
              <p>
                -<mark> React &nbsp;</mark> Having the ability to convert a
                website from HTML to fully React App as i did for this website.
              </p>
              <p>
                -<mark>node.js &nbsp;</mark>
                having the basic and fundamentals of creating node backend,
                created a discord bot about preventing SQL Injection but the bot
                stopped working due to the new privacy of discord.
              </p>
              <p>
                -<mark>Laravel &nbsp;</mark> started recently working with
                Laravel using MVC and going deep into it.
              </p>
            </div>
          </div>
          <div className="content__inner" id="tab-4">
            <div className="page">
              <h3>Mobile Application</h3>
              <p>
                I recently started learning Flutter with Firebase for the
                backend. It reminds me a bit of HTML because of the simple
                variables it uses.
              </p>

              <p>
                -<mark> Museum App &nbsp;</mark>Started designing this project 2
                months ago and still not accomplished yet but feel free to DM me
                and ask me about this project.
              </p>
            </div>
          </div>
          {/*    <div className="content__inner" id="tab-4">
                    <div className="page">
                      <p>Miaow then turn around and show you my bum flee in terror at cucumber discovered on floor. Terrorize the hundred-and-twenty-pound rottweiler and steal his bed, not sorry sleep on dog bed, force dog to sleep on floor and grab pompom in mouth and put in water dish cats are fats i like to pets them they like to meow back present belly, scratch hand when stroked. </p>
                      <p>Bleghbleghvomit my furball really tie the room together love asdflkjaertvlkjasntvkjn (sits on keyboard) but bawl under human beds.</p>
                    </div> 
                  </div>--> */}
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
