import React, { useEffect, useState } from "react";

const HomeSection = () => {
  useEffect(() => {
    const typedTextSpan = document.querySelector(".typed-text");
    const cursorSpan = document.querySelector(".cursor");
    const textArray = ["Alwaleed Blog"];
    const typingDelay = 200;
    const erasingDelay = 100;
    const newTextDelay = 2000;

    let textArrayIndex = 0;
    let charIndex = 0;

    function type() {
      if (charIndex < textArray[textArrayIndex].length) {
        if (!cursorSpan.classList.contains("typing")) {
          cursorSpan.classList.add("typing");
        }
        typedTextSpan.textContent +=
          textArray[textArrayIndex].charAt(charIndex);
        charIndex++;
        setTimeout(type, typingDelay);
      } else {
        cursorSpan.classList.remove("typing");
        setTimeout(erase, newTextDelay);
      }
    }

    function erase() {
      if (charIndex > 0) {
        if (!cursorSpan.classList.contains("typing")) {
          cursorSpan.classList.add("typing");
        }
        typedTextSpan.textContent = textArray[textArrayIndex].substring(
          0,
          charIndex - 1
        );
        charIndex--;
        setTimeout(erase, erasingDelay);
      } else {
        cursorSpan.classList.remove("typing");
        textArrayIndex++;
        if (textArrayIndex >= textArray.length) textArrayIndex = 0;
        setTimeout(type, typingDelay + 1100);
      }
    }

    setTimeout(type, typingDelay + 1100);
  }, []);
  return (
    <section className="container section section__height" id="home">
      <h2 className="section__title"></h2>
      <div className="squares">
        <section className="greetings" id="home">
          <div className="container">
            <p>
              Welcome To <br />
              <span className="typed-text"></span>
              <span className="cursor">&nbsp;</span>
            </p>
          </div>
        </section>
      </div>
    </section>
  );
};

export default HomeSection;
