import React from "react";
import agrnode from "./assets/agrnode.mp4";
import igstory from "./assets/igstory.mp4";
import repsender from "./assets/repsender.mp4";
import tsi from "./assets/tsi.mp4";
import nosender from "./assets/nosender.mp4";
import promo from "./assets/promo.mp4";
import alkhporto from "./assets/alkhporto.mp4";
import cms from "./assets/cms.mp4";
const PortfolioSection = () => {
  return (
    <section className="container section section__height" id="portfolio">
      <h2 className="section__title">Achievements</h2>
      <div className="achiv">
        <div className="container">
          <h4 className="section__title">PYTHON</h4>
          <div className="video-contianer">
            <div className="video">
              <figure>
                <video src={igstory} muted controls></video>
              </figure>
              <figcaption>will give a like to each story.</figcaption>
            </div>
            <div className="video">
              <figure>
                <video src={repsender} muted controls></video>
              </figure>
              <figcaption>special request, auto reporter.</figcaption>
            </div>
            <div className="video">
              <figure>
                <video src={nosender} muted controls></video>
              </figure>
              <figcaption>
                will be given a specific Number and send it a message.
              </figcaption>
            </div>
            <div className="video">
              <figure>
                <video src={promo} muted controls></video>
              </figure>
              <figcaption>
                will be given a list of Numbers and send to all of them.
              </figcaption>
            </div>

            <div className="popup-video">
              <span>&times;</span>
              <video src={repsender} muted controls></video>
            </div>
          </div>
          <h4 className="section__title">WEB</h4>
          <div className="video-contianer">
            <div className="video">
              <figure>
                <video src={agrnode} muted controls></video>
              </figure>
              <figcaption>
                <a
                  href="https://agri-innovate.com"
                  style={{ color: "inherit", textDecoration: "none" }}
                  target="_blank"
                >
                  agriculture Innoviations website.
                  <br /> "Click to see"
                </a>
              </figcaption>
            </div>
            <div className="video">
              <figure>
                <video src={cms} muted controls></video>
              </figure>
              <figcaption>
                <a
                  href="https://www.teamskyiscrape.com"
                  style={{ color: "inherit", textDecoration: "none" }}
                  target="_blank"
                >
                  a website designed for a Discord Community. "Click to see"
                </a>
              </figcaption>
            </div>
            <div className="video">
              <figure>
                <video src={alkhporto} muted controls></video>
              </figure>
              <figcaption>
                <a
                  href="https://alkhalilacademy.com/"
                  style={{ color: "inherit", textDecoration: "none" }}
                  target="_blank"
                >
                  Mr. Alkhalil Portfolio. "Click to see"
                </a>
              </figcaption>
            </div>
            <div className="video">
              <figure>
                <video src={cms} muted controls></video>
              </figure>
              <figcaption>
                a Carage Management System <br /> FOR SALE
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
