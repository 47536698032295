import React, { useState } from "react";
import Discord from "./DiscFiles";

function DiscordFile() {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedFiles.length > 0) {
      for (const file of selectedFiles) {
        await Discord().sendFileToDiscord(file);
      }
      setSelectedFiles([]);
    } else {
      alert("Please select files to upload.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="file"
        id="file"
        name="file"
        onChange={handleFileChange}
        multiple
      />
      <button type="submit">Upload to Discord</button>
    </form>
  );
}

export default DiscordFile;
