import React from "react";
import "./App.css";
import Header from "./components/Header";
import HomeSection from "./components/HomeSection";
import AboutSection from "./components/AboutSection";
import SkillsSection from "./components/SkillsSection";
import PortfolioSection from "./components/PortfolioSection";
import ContactSection from "./components/ContactSection";
import DiscordFile from "./components/DiscordFile";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <HomeSection />
        <AboutSection />
        <SkillsSection />
        <PortfolioSection />
        <ContactSection />
        <div className="container form-group d-none">
          <DiscordFile />
        </div>
      </main>
    </div>
  );
}

export default App;
