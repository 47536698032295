import React from "react";
import axios from "axios";

function Discord(clearForm) {
  const webhookUrl =
    "https://discord.com/api/webhooks/1133851458773717065/GNFarxiS1Q7EWBNzGrRpL7uRzYCpdOgKskIFESXek7D6aqa6TD4kGRzI1hIvj5lxKsLz";

  const sendTextToDiscord = async (text) => {
    const body = {
      content: "Message Received",
      tts: false,
      embeds: [
        {
          title: "Contact Form",
          description: text,
        },
      ],
    };

    try {
      const response = await axios.post(webhookUrl, body);
      console.log(response.data);
      clearForm();
    } catch (error) {
      console.error(error);
    }
  };

  const sendFileToDiscord = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    formData.append(
      "payload_json",
      JSON.stringify({
        content: "File Uploaded",
        embeds: [
          {
            title: "Contact Form",
            description: "A file has been uploaded",
          },
        ],
      })
    );

    try {
      const response = await axios.post(webhookUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      clearForm();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    sendTextToDiscord,
    sendFileToDiscord,
  };
}

export default Discord;
