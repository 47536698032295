import React from "react";
import axios from "axios";

function Discord(clearForm) {
  const Send = async (data) => {
    const body = {
      content: "Message Received",
      tts: false,
      color: "white",
      embeds: [
        {
          title: "Contact Form",
          description: data,
        },
      ],
    };

    try {
      const data = await axios.post(
        "https://discord.com/api/webhooks/1133851458773717065/GNFarxiS1Q7EWBNzGrRpL7uRzYCpdOgKskIFESXek7D6aqa6TD4kGRzI1hIvj5lxKsLz",
        body
      );
      console.log(data);
      clearForm();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    Send,
  };
}

export default Discord;
