import React, { useState, useEffect } from "react";
import me from "./assets/me.jpg";
const Header = () => {
  const [activeLink, setActiveLink] = useState("");

  const handleScroll = () => {
    const sections = document.querySelectorAll("section[id]");
    const scrollY = window.pageYOffset;

    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 50;
      const sectionId = current.getAttribute("id");

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        setActiveLink(sectionId);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header" id="header">
      <nav className="nav container">
        <a href="#" className="nav__logo">
          Alwaleed's Blog
        </a>
        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item">
              <a
                href="#home"
                className={`nav__link ${
                  activeLink === "home" ? "active-link" : ""
                }`}
              >
                <i className="bx bx-home-alt nav__icon"></i>
                <span className="nav__name">Home</span>
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#about"
                className={`nav__link ${
                  activeLink === "about" ? "active-link" : ""
                }`}
              >
                <i className="bx bx-user nav__icon"></i>
                <span className="nav__name">About</span>
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#skills"
                className={`nav__link ${
                  activeLink === "skills" ? "active-link" : ""
                }`}
              >
                <i className="bx bx-book-alt nav__icon"></i>
                <span className="nav__name">Skills</span>
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#portfolio"
                className={`nav__link ${
                  activeLink === "portfolio" ? "active-link" : ""
                }`}
              >
                <i className="bx bx-briefcase-alt nav__icon"></i>
                <span className="nav__name">Achievements</span>
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#contactme"
                className={`nav__link ${
                  activeLink === "contactme" ? "active-link" : ""
                }`}
              >
                <i className="bx bx-message-square-detail nav__icon"></i>
                <span className="nav__name">Contact Me</span>
              </a>
            </li>
          </ul>
        </div>
        <img src={me} className="nav__img" alt="a picture of me" />
      </nav>
    </header>
  );
};

export default Header;
